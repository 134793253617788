import './App.css';

function App() {
  return (
    <>
    <div className="container">
    Hehehe, wrong website sike!
    </div>
    
    </>
  );
}

export default App;
